<template>
    <b-container>
        <h3>Anleitung</h3>
        <p>Wähle deine Abteilung und deine Gruppe aus, gib deinen Namen ein und starte das Rätsel. Das Ziel ist es, das Rätsel schneller als deine Mitleitenden zu lösen!
            Stellt danach das Lösungswort möglichst Kreativ (mit Fotos) in eurem Stamm / Einheit dar und sendet es an <a href="mailto:rover@gloggi.ch">rover@gloggi.ch</a>. Wer gewonnen hat, wird am 28.02.2021 am Roveranlass bekanntgegeben.
        </p>
        <b-form @submit.prevent="checkUserExists">
            <b-form-group label="Abteilung">
                <b-select class="g-form" v-model="abteilung" :options="formattedAbteilungen" required></b-select>
            </b-form-group>
            <b-form-group label="Gruppe" v-if="abteilung">
                <b-select class="g-form" v-model="group" :options="formattedGroups" required></b-select>
            </b-form-group>
            <b-form-group v-if="group" label="Pfadiname">
                <b-input class="g-form" v-model="nickname" type="text" required></b-input>
            </b-form-group>
            <b-form-group v-if="nickname">
                <b-button class="gloggi-btn" type="submit">Start</b-button>
            </b-form-group>
        </b-form>
        <div style="text-align: center" v-if="spinner">
        <b-spinner class="spinner" style="width: 3rem; height: 3rem;"></b-spinner>
        </div>
    </b-container>
</template>

<script>
    export default {
        name: "Start",
        props: ["content"],
        data(){
            return{
                abteilung: null,
                group: null,
                nickname: null,
                spinner: false
            }
        },
        computed:{
            formattedAbteilungen(){
                var abteilungen = []
                if("abteilungen" in this.content){
                this.content.abteilungen.forEach(abteilung=>{
                    abteilungen.push({text: abteilung.name, value: abteilung})
                })
                }
                return abteilungen
            },
            formattedGroups(){
                var groups = []
                if("groups" in this.content) {
                    this.content.groups.forEach(group => {
                        if (group.abteilung.id == this.abteilung.id) {
                            groups.push({text: group.name, value: group})
                        }
                    })
                }
                return groups
            }
        },
        methods:{
            checkUserExists(){
                this.$api.getItems('solved_cross_words', {fields: '*.*',filter:{ solver: {eq: this.nickname}, group: {eq: this.group.id}}}).then(response=>{
                    if(response.data.length>0){
                        document.cookie = JSON.stringify({name: this.nickname, group: this.group, abteilung: this.abteilung})
                        this.$router.push({ name: 'crossword', params: { abteilung: this.abteilung.name, group: this.group.name, name: this.nickname }})
                    }else{
                        this.start()
                    }
                })
            },
            start(){
                document.cookie = JSON.stringify({name: this.nickname, group: this.group, abteilung: this.abteilung})
                this.spinner = true
                var cross_word=this.content.cross_words.find(crossWord=>crossWord.id===this.group.cross_word.id)
                var letters = Object.assign([],cross_word.letters)
                var questions = Object.assign([],cross_word.questions)
                var sol_word_letters = Object.assign([],cross_word.sol_word_letters)
                delete cross_word.letters
                delete cross_word.sol_word_letters
                delete cross_word.questions
                delete cross_word.id
                delete cross_word.created_on
                delete cross_word.owner
                cross_word.solver = this.nickname
                cross_word.group = this.group.id

                this.$api.createItem('solved_cross_words', cross_word).then(response=>{
                    var id = response.data.id
                    for( var letter in letters){
                        delete letters[letter].id
                        delete letters[letter].created_on
                        delete letters[letter].owner
                        delete letters[letter].cross_word
                        letters[letter].sol_cross_word = id
                    }
                    for(var sol_letter in sol_word_letters){
                        delete sol_word_letters[sol_letter].id
                        delete sol_word_letters[sol_letter].created_on
                        delete sol_word_letters[sol_letter].cross_word
                       sol_word_letters[sol_letter].sol_cross_word = id
                    }
                    for (var question in questions){
                        delete questions[question].id
                        delete questions[question].created_on
                        delete questions[question].cross_word
                        questions[question].sol_cross_word = id

                    }
                    this.$api.updateItem('solved_cross_words',id,{letters: letters,
                        sol_word_letters: sol_word_letters, questions: questions}).then(()=>{
                        this.$router.push({ name: 'crossword', params: { abteilung: this.abteilung.name, group: this.group.name, name: this.nickname }})
                    })
                })

            },
            checkForCookie(){
                if(document.cookie!==""){
                    document.cookie = "";
                    //var data = JSON.parse(document.cookie)
                   // this.$router.push({ name: 'crossword', params: { abteilung: data.abteilung.name, group: data.group.name, name: data.name }})
                }
            }
        },
        created() {
            this.checkForCookie()
        }
    }
</script>

<style scoped>

</style>