<template>
    <div>
        <b-row>
            <b-col>
                <b-button class="mb-3 gloggi-btn" v-b-toggle.collapse-ranking>
                    <b-icon-trophy></b-icon-trophy>
                </b-button>
            </b-col>
            <b-col>
                <div class="text-right">
                    <h3 v-if="rankingKey>0">Zeit: {{ formatDuration(timeCounter) }}</h3>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-collapse id="collapse-ranking" :visible="succMsg">
                    <table class="table">
                        <tr>
                            <th>#</th>
                            <th>Pfadiname</th>
                            <th>Zeit</th>
                        </tr>
                        <tr v-for="(entry, i) in rankingOutput" :class="entry.name===$route.params.name?'me':''"
                            :key="entry.id">
                            <td>{{i+1}}</td>
                            <td>{{entry.name}}</td>
                            <td>{{formatDuration(entry.time)}}</td>
                        </tr>
                    </table>
                </b-collapse>
            </b-col>

        </b-row>
        <b-row :key="displayKey">
            <b-col>
                Ä = AE
                <div id="crossword" v-if="loadGrid">
                    <table class="cwt" :key="gridHeight">
                        <tr class="tr" v-for="(indexOne,i) in gridHeight" :key="i">
                            <td class="td" v-for="(indexTwo,j) in gridWidth" :key="i * gridWidth + j"
                                :class="styleField(grid[i * gridWidth + j])">
                                <input v-if="grid[i * gridWidth + j].enabled&&!grid[i * gridWidth + j].hidden"
                                       @keydown="(event)=>{switchField(event, grid[i * gridWidth + j].key)}"
                                       :ref="i * gridWidth + j" class="w-inp"
                                       :style="navigBackgroundImage(grid[i * gridWidth + j])+solBackgroundImage(grid[i * gridWidth + j])"
                                       v-model="grid[i * gridWidth + j].letter" type="text"
                                       @keyup="(event)=>{formatLetter(grid[i * gridWidth + j]); navigate(i * gridWidth + j,event)}"
                                       @focusout="()=>{updateLetter(grid[i * gridWidth + j])}"
                                >
                            </td>
                        </tr>
                    </table>
                    <table class="mt-2">
                        <tr>
                            <td class="td" v-for="letter in solWord" :key="letter.key">
                                <input v-if="letter.key in grid"
                                       @keydown="(event)=>{switchField(event, 's'+letter.key)}"
                                       :ref="'s'+letter.key" class="w-inp" maxlength="1"
                                       :style="solBackgroundImage(letter)"
                                       v-model="grid[letter.key].letter" type="text"
                                       disabled>
                                <input v-else
                                       @keydown="(event)=>{switchField(event, 's'+letter.key)}"
                                       :ref="'s'+letter.key" class="w-inp" maxlength="1"
                                       :style="solBackgroundImage(letter)"
                                       v-model="letter.letter" type="text"
                                       disabled>
                            </td>
                        </tr>
                    </table>
                </div>
            </b-col>
            <b-col>
                <div id="questions">
                    <h3>Fragen</h3>
                    <div v-for="question in questions" :key="question.id" @click="()=>{questionFocus(question.key)}"
                         class="card mb-2 question-card">
                        <div class="card-body"><b>{{question.key}}.</b>
                            {{question.question}}
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: 'CrossWord',
        props: ["content", "displayKey"],
        data() {
            return {
                gridHeight: 0,
                gridWidth: 0,
                grid: null,
                modalID: 0,
                gridKey: 0,
                solWord: null,
                key: 0,
                questions: null,
                loadGrid: false,
                succMsg: false,
                group: null,
                ranking: [],
                rankingKey: 0,
                lastIdx: 0,
                time: {
                    start: null,
                    end: null
                },
                timeCounter: null,
                countTime: false
            }
        },
        computed: {
            rankingOutput() {
                return this.ranking
            }
        },
        watch: {
            grid: {
                handler(newGrid) {
                    var complete = true
                    for (var i in newGrid) {
                        if (newGrid[i].sol_number > 0) {
                            complete = complete && newGrid[i].letter.toLocaleLowerCase() === newGrid[i].sol.toLocaleLowerCase()
                        }
                    }
                    if (complete && !this.succMsg) {
                        var dt = new Date()
                        this.countTime = false
                        this.time.end = dt.getTime()
                        this.succMsg = true
                        this.$root.$emit('bv::toggle::collapse', 'collapse-ranking')
                        this.$confetti.start({defaultColors: ["red", "white"]});
                        this.setRanking()
                        setTimeout(() => {
                            this.$confetti.stop();
                        }, 5000)
                    }
                }, deep: true
            }


        },
        methods: {
            formatDuration(duration) {
                var dur = this.$moment.duration(duration)
                var hours = dur.hours()
                var minutes = dur.minutes()
                var seconds = dur.seconds()
                seconds < 9 ? seconds = "0" + seconds : seconds;
                minutes > 0 && minutes < 9 ? minutes = "0" + minutes : minutes;

                if (hours > 0) {
                    return hours + ':' + minutes + ':' + seconds + " h"
                } else if (minutes > 0) {
                    return minutes + ':' + seconds + " min"
                } else {
                    return seconds + ' sec'
                }
            },
            timer() {
                if (this.countTime) {
                    setInterval(this.timer, 100)
                    var dt = new Date()
                    this.timeCounter = dt.getTime() - this.time.start
                }

            },
            questionFocus(number) {
                var key = Object.values(this.grid).find(item => item.right === number || item.down === number)
                this.$refs[key.key][0].focus()
                if (key.down > 0) {
                    this.lastIdx = key.key - this.gridWidth
                } else {
                    this.lastIdx = key.key - 1
                }

            },
            navigate(idx, event) {
                if (event.key === "ArrowDown" || event.key === "ArrowUp" ||
                    event.key === "ArrowLeft" || event.key === "ArrowRight" ||
                    event.key === "Backspace" || event.key === "Shift") {
                    return ''
                }
                if (typeof this.$refs[idx + 1] !== 'undefined' &&
                    typeof this.$refs[idx + this.gridWidth] === 'undefined') {
                    this.$refs[idx + 1][0].focus()
                } else if (typeof this.$refs[idx + 1] === 'undefined' &&
                    typeof this.$refs[idx + this.gridWidth] !== 'undefined') {
                    this.$refs[idx + this.gridWidth][0].focus()
                } else {
                    if (idx - this.lastIdx === 1 && typeof this.$refs[idx + 1] !== 'undefined') {
                        this.$refs[idx + 1][0].focus()
                    } else if (idx - this.lastIdx === this.gridWidth && typeof this.$refs[idx + this.gridWidth] !== 'undefined') {
                        this.$refs[idx + this.gridWidth][0].focus()
                    } else if (idx + 1 < this.gridWidth * this.gridHeight && this.grid[idx + 1].hidden) {
                        this.$refs[idx + 2][0].focus()
                        this.lastIdx = idx + 1
                        return
                    } else if (idx + this.gridWidth < this.gridWidth * this.gridHeight && this.grid[idx + this.gridWidth].hidden) {
                        this.$refs[idx + 2 * this.gridWidth][0].focus()
                        this.lastIdx = idx + this.gridWidth;
                    } else {
                        while (typeof this.$refs[(idx + 1) % (this.gridWidth * this.gridHeight)] === 'undefined' ||
                        this.grid[(idx + 1) % (this.gridWidth * this.gridHeight)].letter !== "" || (this.grid[(idx + 1) % (this.gridWidth * this.gridHeight)].right === 0 &&
                            this.grid[(idx + 1) % (this.gridWidth * this.gridHeight)].down === 0)) {
                            idx++
                            if (idx > 2 * this.gridWidth * this.gridHeight) {
                                break;
                            }
                        }
                        if (idx + 1 < 2 * this.gridWidth * this.gridHeight) {
                            this.$refs[(idx + 1) % (this.gridWidth * this.gridHeight)][0].focus()
                        }
                    }
                }
                this.lastIdx = idx
            },
            getRanking() {
                this.$api.getItems('ranking', {fields: '*.*', filter: {group: {eq: this.group.id}}}).then(response => {
                    this.ranking = response.data
                    if (this.ranking.length > 1) {
                        this.ranking.sort((a, b) => {
                            return a.time - b.time
                        })
                    }
                    this.rankingKey++
                })
            },
            setRanking() {
                this.$api.getItems('ranking', {
                    fields: '*.*',
                    filter: {name: {eq: this.$route.params.name}, group: {eq: this.group.id}}
                }).then(response => {
                    if (!(response.data.length > 0)) {
                        this.$api.createItem('ranking', {
                            name: this.$route.params.name,
                            group: this.group.id,
                            time: this.timeCounter
                        }).then(() => {
                            this.getRanking()
                        })
                    } else {
                        this.countTime = false
                        this.timeCounter = this.ranking.find(entry => entry.name === this.$route.params.name).time
                    }
                })

            },
            formatLetter(entry) {
                if (entry.letter.length > 1) {
                    entry.letter = entry.letter.substr(entry.letter.length - 1)
                }

            },
            findCrossWord(name, groupName, abteilungName) {

                var group = this.content.groups.find(group => group.name === groupName && group.abteilung.name === abteilungName)
                this.group = group
                this.getRanking()
                this.$api.getItems('solved_cross_words', {
                    fields: '*.*',
                    filter: {solver: {eq: name}, group: {eq: group.id}}
                }).then(result => {
                    if (result.data.length > 0) {
                        this.formatGrid(result.data[0])
                        var dt = new Date(result.data[0].created_on)
                        this.time.start = dt.getTime()
                        this.countTime = true
                        this.timer()
                    }

                })
            },
            formatGrid(grid) {
                var letters = {}
                grid.letters.forEach(letter => {
                    letters[letter.key] = letter
                })
                var solWord = grid.sol_word_letters.sort((a, b) => {
                    return a.sol_number > b.sol_number ? 1 : -1
                })
                this.grid = letters
                this.questions = grid.questions
                this.solWord = solWord
                this.gridHeight = grid.height
                this.gridWidth = grid.width
                this.key++
                this.loadGrid = true
            },
            updateLetter(letter) {
                this.$api.updateItem('letters', letter.id, letter)
            },
            styleField(field) {
                var style = ""
                if (!field.enabled) {
                    style = style + "disabled "
                }
                if (field.hidden) {
                    style = style + "hidden "
                }
                return style

            },
            switchField(event, id) {
                var maxind = this.gridWidth * this.gridHeight
                switch (event.key) {
                    case "ArrowDown":
                        id += this.gridWidth
                        while (typeof this.$refs[id] === 'undefined' && id < maxind) {
                            id += this.gridWidth
                        }
                        if (id < maxind) {
                            this.$refs[id][0].focus()
                        }
                        break;
                    case "ArrowUp":
                        id -= this.gridWidth
                        while (typeof this.$refs[id] === 'undefined' && id >= 0) {
                            id -= this.gridWidth
                        }
                        if (id >= 0) {
                            this.$refs[id][0].focus()
                        }
                        break;
                    case "ArrowLeft":
                        id -= 1
                        while (typeof this.$refs[id] === 'undefined' && id >= 0) {
                            id -= 1
                        }
                        if (id >= 0) {
                            this.$refs[id][0].focus()
                        }
                        break;
                    case "ArrowRight":
                        id += 1
                        while (typeof this.$refs[id] === 'undefined' && id < maxind) {
                            id += 1
                        }
                        if (id < maxind) {
                            this.$refs[id][0].focus()
                        }
                        break;

                }

            },
            navigBackgroundImage(l) {
                var numbers = ''
                if (l.right !== 0 && l.down !== 0) {
                    numbers = l.right + '&#10148;' + l.down + '&#11167;'
                } else if (l.right !== 0) {
                    numbers = l.right + '</text><polygon points="18.7,8.6 18.7,5.6 21.2,7.1 23.7,8.6 21.2,10 18.7,11.5  \t"/><text>'
                } else if (l.down !== 0) {
                    numbers = l.down + '</text><polygon points="21.2,6 24.2,6 22.7,8.6 21.2,11.1 19.8,8.6 18.3,6 \t"/><text>'
                } else {
                    return ''
                }
                var output = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><style type="text/css">   .st0{fill:rgba(255,255,255,0);} .st1{fill:none;} .st3{font-size:12px;} </style><rect class="st0" width="50" height="50"/><rect x="2.8" y="3.2" class="st1" width="12.2" height="10.7"/><text transform="matrix(1 0 0 1 5.8428 11.6836)" class="st2 st3">' +
                    numbers +
                    '</text></svg>')
                var doubleBackground = '';
                if (l.sol_number > 0) {
                    doubleBackground = ','
                } else {
                    doubleBackground = ';'
                }
                return 'background-image: url(' + "data:image/svg+xml;base64," + output + ')' + doubleBackground
            },
            solBackgroundImage(l) {
                var numbers = ''
                if (l.sol_number > 0) {
                    numbers = l.sol_number
                } else {
                    return ''
                }
                var doubleBackground = ''
                if (typeof l.enabled === 'undefined' || l.down === 0 && l.right === 0) {
                    doubleBackground = 'background-image:'
                }
                var output = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><style type="text/css"> .st0{fill:#D1D1D1;} .st1{fill:none;} .st2{fill:#FFFFFF;} .st3{font-family:&#39;Arial-BoldMT&#39;;} .st4{font-size:12px;} </style><rect class="st0" width="50" height="50"/><rect x="21.6" y="37.6" class="st1" width="25" height="9"/><text transform="matrix(1 0 0 1 35.8973 46.1631)" style="text-align: right" class="st2 st3 st4">'
                    + numbers +
                    '</text></svg>')
                return doubleBackground + ' url(' + "data:image/svg+xml;base64," + output + ');'
            },

        },
        mounted() {
            this.findCrossWord(this.$route.params.name, this.$route.params.group, this.$route.params.abteilung)
        }

    }
</script>

<style>

    #questions, #crossword {
        margin: 60px;
    }

    .question {
        font-size: 20px;
        margin-bottom: 5px;
        cursor: pointer;
    }

    .question-card {
        cursor: pointer;

    }

    .question-card:focus, .question-card:hover {
        background-color: #d2585f;
    }

    tr.me {
        background-color: #42b983;
    }

    .block {
        display: block;
    }


    td.td {
        height: 50px !important;
        width: 50px !important;
        border: 1px solid black;
        padding: 0;
    }


    td.disabled {
        border: 0 !important;
        padding: 0;
    }

    td.hidden {
        background-color: black;
    }

    .w-inp, .w-inp:focus {
        height: 50px !important;
        width: 50px !important;
        border-radius: 0;
        border-width: 0;
        padding: 0;
        border-color: transparent;
        text-align: center;
        font-size: 30px;
        text-transform: uppercase;
        outline: 0;
    }

    .w-inp:disabled {
        background-color: transparent !important;
        color: black;
    }

    @media (max-width: 480px) {
        #questions, #crossword {
            margin: 1px;
        }

        table.cwt {
            margin-left: auto;
            margin-right: auto;
        }

        td.td {
            padding: 0 !important;
            margin: 0 !important;
            display: table-cell;
            vertical-align: center;
        }

        td.td, tr.tr, .w-inp, .w-inp:focus {
            height: 18px !important;
            width: 18px !important;

        }

        .w-inp, .w-inp:focus {
            font-size: 15px;
            padding: 0 !important;
            margin: 0 !important;
            display: block;
        }
    }

</style>
