import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import DirectusSDK from "@directus/sdk-js";

const client = new DirectusSDK({
  url: "https://api.gloggi.ch/",
  project: "thinking-day",
  storage: window.localStorage
});
Vue.prototype.$api=  client

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import {routes } from './routes'
const router = new VueRouter({
  mode: 'history',
  routes
})
import VueConfetti from 'vue-confetti';

Vue.use(VueConfetti);
Vue.use(require('vue-moment'));

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
