<template>
    <b-container v-if="loaded">
        <div class="card mt-5 main">
            <div class="card-header bg-image">
                <img src="./assets/img/banner.jpg" width="100%">
            </div>
          <div class="card-body">
            <router-view :content="content" v-if="loaded" :displayKey="displayKey" :key="displayKey"></router-view>
          </div>
          </div>
    </b-container>
</template>

<script>


    export default {
        name: 'App',
        data() {
            return {
                items: ["abteilungen", "groups", "cross_words"],
                content: {},
                gridSize: 10,
                loaded: false,
                displayKey: 0
            }
        },
        methods: {
            loadData() {
                this.items.forEach(item => {
                    this.loadItem(item).then(response => {
                        this.content[item] = response.data
                        if (Object.keys(this.content).length === this.items.length) {
                            this.displayKey++
                            this.loaded = true
                        }
                    })
                })
            },
            async loadItem(item) {
                return this.$api.getItems(item, {fields: '*.*'})

            }

        },
        created() {
            this.loadData()
        }
    }
</script>

<style>
    body{
        background-color: #d22630!important;
    }
    .bg-image{
        padding: 0!important;
        border-color: #d22630!important;
    }
    .card.main{
        border-color: #d22630!important;
    }
    .gloggi-btn{
        background-color: #d22630!important;
        border-color: rgba(210, 38, 48, 0.8) !important;
    }
    .gloggi-btn:hover, .gloggi-btn:active{
        box-shadow: 0 0 0 0.1rem rgba(176, 48, 44, 0.5)!important;
    }
    .g-form:focus{
        box-shadow: 0 0 0 0.1rem rgba(176, 48, 44, 0.5)!important;
        border-color: #d22630!important;
    }
    .spinner{
        color: #d22630;
    }
    a{
        color: #d22630!important;
    }


</style>
